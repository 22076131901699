import React from 'react';
import styled from 'styled-components';
import '@fontsource/zcool-kuaile'; // Import the Chinese font
import '@fontsource/pacifico'; // Import the English font

const FooterWrapper = styled.div`
  font-family: 'ZCOOL KuaiLe', 'Pacifico', sans-serif;
  font-size: 24px;
  color: black;
  text-align: center;
  margin: 20px 0;
  transform: rotate(${props => props.rotation}deg);
`;

function FooterContent({ content, rotation = 0 }) {
  return (
    <FooterWrapper rotation={rotation}>
      &nbsp;&nbsp;&nbsp;{content}
    </FooterWrapper>
  );
}

export default FooterContent;