/*
 * 翻牌时钟
 * @author： 兔子先生
 * @createDate: 2019-11-24
 */
import React, { Component } from 'react';
import Flipper from './Flipper';
import './flipClock.css';

class FlipClock extends Component {
    constructor(props) {
        super(props);
        this.timer = null;
        this.flipObjs = [];
        this.startDate = new Date('2023-04-16T11:54:27');
    }

    render() {
        // 确定是否需要四位天数
        const now = new Date();
        const diff = now - this.startDate;
        const days = Math.floor(diff / 1000 / 60 / 60 / 24);
        const needsFourthDay = days >= 1000;

        return (
            <div className="FlipClock">
                {needsFourthDay && <Flipper ref="flipperDay1" />}
                <Flipper ref="flipperDay2" />
                <Flipper ref="flipperDay3" />
                <Flipper ref="flipperDay4" />
                <em>天</em>
                <Flipper ref="flipperHour1" />
                <Flipper ref="flipperHour2" />
                <em>时</em>
                <Flipper ref="flipperMinute1" />
                <Flipper ref="flipperMinute2" />
                <em>分</em>
                <Flipper ref="flipperSecond1" />
                <Flipper ref="flipperSecond2" />
                <em>秒</em>
            </div>
        );
    }

    componentDidMount() {
        const now = new Date();
        const diff = now - this.startDate;
        const days = Math.floor(diff / 1000 / 60 / 60 / 24);
        const needsFourthDay = days >= 1000;

        this.flipObjs = [
            ...(needsFourthDay ? [this.refs.flipperDay1] : []),
            this.refs.flipperDay2,
            this.refs.flipperDay3,
            this.refs.flipperDay4,
            this.refs.flipperHour1,
            this.refs.flipperHour2,
            this.refs.flipperMinute1,
            this.refs.flipperMinute2,
            this.refs.flipperSecond1,
            this.refs.flipperSecond2
        ].filter(Boolean); // 过滤掉 undefined 的引用

        this.init();
        this.run();
    }

    // 初始化数字
    init() {
        let now = new Date();
        let timeDiffStr = this.formatTimeDifference(now - this.startDate);
        for (let i = 0; i < this.flipObjs.length; i++) {
            this.flipObjs[i].setFront(timeDiffStr[i]);
        }
    }

    // 开始计时
    run() {
        this.timer = setInterval(() => {
            let now = new Date();
            let prevTimeDiffStr = this.formatTimeDifference(now - this.startDate - 1000);
            let nextTimeDiffStr = this.formatTimeDifference(now - this.startDate);
            for (let i = 0; i < this.flipObjs.length; i++) {
                if (prevTimeDiffStr[i] === nextTimeDiffStr[i]) {
                    continue;
                }
                this.flipObjs[i].flipDown(prevTimeDiffStr[i], nextTimeDiffStr[i]);
            }
        }, 1000);
    }

    // 计算时间差并格式化为字符串
    formatTimeDifference(diff) {
        let seconds = Math.floor(diff / 1000) % 60;
        let minutes = Math.floor(diff / 1000 / 60) % 60;
        let hours = Math.floor(diff / 1000 / 60 / 60) % 24;
        let days = Math.floor(diff / 1000 / 60 / 60 / 24);

        const daysStr = days >= 1000 ? this.padLeftZero(days.toString(), 4) : this.padLeftZero(days.toString(), 3);
        return (
            daysStr +
            this.padLeftZero(hours.toString(), 2) +
            this.padLeftZero(minutes.toString(), 2) +
            this.padLeftZero(seconds.toString(), 2)
        );
    }

    // 补零
    padLeftZero(str, length) {
        return ('0'.repeat(length) + str).slice(-length);
    }
}

export default FlipClock;