import React, { useState } from "react";
import Card from "react-bootstrap/Card";
import TravelModal from "./TravelDetail";

function TravelCard(props) {
  let [show, setShow] = useState(false);
  const handleClick = ()=>setShow(true);
  const handleClose = ()=>setShow(false);

  return (
    <>
    <Card className="project-card-view" onClick={handleClick}>
      <Card.Img variant="top" src={props.imgPath} alt="card-img" />
      <Card.Body>
        <Card.Title className="purple">{props.title}</Card.Title>
        <Card.Text style={{ textAlign: "justify" }}>
          {props.description}
        </Card.Text>
        {"\n"}
        {"\n"}
      </Card.Body>
    </Card>
    <TravelModal show={show} handleClose={handleClose} markdown={props.markdown} />
    </>
  );
}
export default TravelCard;
