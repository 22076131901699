import React from 'react';
import { Modal } from 'react-bootstrap';
import Markdown from 'react-markdown';
import rehypeRaw from 'rehype-raw'

function TravelModal(props) {
    return (
      <Modal
        show={props.show}
        onHide={props.handleClose}
        dialogClassName="custom-modal"
        centered
      >
        <Modal.Header closeButton />
        <Modal.Body>
          <Markdown className="markdown-content" rehypePlugins={[rehypeRaw]}>{props.markdown}</Markdown>
        </Modal.Body>
      </Modal>
    );
}


export default TravelModal;