import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import hyq from "../../Assets/hyq.jpg";
import yth from "../../Assets/yth.jpg";
import Particle from "../Particle";
import Home2 from "./Home2";
import Type from "./Type";
import FlipClock from "./Flip/FlipClock";

function Home() {
  return (
    <section>
      <Container fluid className="home-section" id="home">
        <Particle />
        <Container className="home-content">
          <Row>
            <Col md={7} className="home-header" style={{ paddingBottom: 180}}>
              <h1 style={{ paddingBottom: 15 }} className="heading">
                Hey There!{" "}
                <span className="wave" role="img" aria-labelledby="wave">
                  👋🏻
                </span>
              </h1>

              <h1 className="heading-name">
                这里是
                <strong className="main-name"> 英俊和义秋 </strong>站！
              </h1>

              <div style={{ padding: 50, textAlign: "left" }}>
                <Type />
              </div>
            </Col>

            <Col md={5} style={{ paddingBottom: 150, paddingTop: 80 }}>
              <div style={{ paddingBottom: 40, textAlign: "center" }}>
              <img
                src={hyq}
                alt="home pic"
                className="img-fluid"
                style={{
                  borderRadius: "50%",
                  width: "60px",
                  height: "60px",
                  verticalAlign: "middle"
                }}
              />
              <strong className="home-font" style={{ verticalAlign: "middle" }}> & </strong>
              <img
                src={yth}
                alt="home pic"
                className="img-fluid"
                style={{
                  borderRadius: "50%",
                  width: "60px",
                  height: "60px",
                  verticalAlign: "middle"
                }}
              />
              <strong className="home-font" style={{ verticalAlign: "middle" }}> have been together for: </strong>
              </div>
              <FlipClock/>
            </Col>
          </Row>
        </Container>
      </Container>
      <Home2 />
    </section>
  );
}

export default Home;
