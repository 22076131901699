import React, { useEffect, useState } from 'react';
import { InstantPhoto } from 'instant-photo';
import { Col, Row, Container } from 'react-bootstrap';
import FooterContent from './Footer';

function Gallery() {
  const [photos, setPhotos] = useState([]);

  useEffect(() => {
    const fetchgallery = async () => {
        try {
          const response = await fetch('/v1/gallery/feed');
          const data = await response.json();
          setPhotos(data);
        } catch (error) {
          console.error('Error fetching gallery:', error);
        } 
    };
    fetchgallery();
  }, []);

  const renderRows = () => {
    const rows = [];
    for (let i = 0; i < photos.length; i += 5) {
      const cols = photos.slice(i, i + 5).map(photo => (
        <Col key={photo.id}>
          <InstantPhoto 
            filter="none"
            size="small"
            rotation={photo.rotation}
            footer={photo.footer && <FooterContent content={photo.footer} />}
            image={photo.url}
          />
        </Col>
      ));
      rows.push(<Row key={i} className="mt-4">{cols}</Row>);
    }
    return rows;
  };

  return (
    <section>
      <Container className="gallery-content">
        {renderRows()}
      </Container>
    </section>
  );
}

export default Gallery;

