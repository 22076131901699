import React from "react";
import Typewriter from "typewriter-effect";

function Type() {
  return (
    <Typewriter
      options={{
        strings: [
          "“根，紧握在地下；叶，相触在云里。每一阵风过，我们都互相致意。但没有人，听懂我们的言语。”",
        ],
        autoStart: true,
        loop: true,
        deleteSpeed: 80,
      }}
    />
  );
}

export default Type;
