import React, { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import { Chrono } from "react-chrono";

const Timeline = () => {
  const [items, setItems] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchItems = async () => {
      try {
        const response = await fetch('/v1/timeline/feed', {
          mode: 'cors'
        });
        const data = await response.json();
        setItems(data);
      } catch (error) {
        console.error('Error fetching timeline items:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchItems();
  }, []); // 空数组表示这个 useEffect 只在组件挂载时运行一次

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <Container fluid className="timeline-section">
        <Chrono items={items} mode="VERTICAL_ALTERNATING" disableToolbar={true} cardHeight="90" mediaHeight="200" enableDarkToggle="true"/>
    </Container>
  )
}

export default Timeline;