import React, { useEffect, useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import TravelCard from "./TravelCard";
import Particle from "../Particle";

function Travel() {
  const [cards, setCards] = useState([]);
  
  useEffect(()=>{
    const fetchTravel = async () => {
      try {
        const response = await fetch('/v1/travel/feed', {
          mode: 'cors'
        });
        const data = await response.json();
        setCards(data);
      } catch (error) {
        console.error('Error fetching travel data:', error);
      }
    };
    fetchTravel();
  }, []);

  const renderRows = () => {
    const rows = [];
    for (let i = 0; i < cards.length; i += 3) {
      const cols = cards.slice(i, i+3).map(
        card => (
          <Col key={card.id} md={4} className="project-card">
            <TravelCard
              imgPath={card.coverUrl}
              title={card.title}
              description={card.describe}
              markdown={card.markdown}
            />
          </Col>
      ));
      rows.push(<Row style={{ justifyContent: "center", paddingBottom: "10px" }}>{cols}</Row>)
    }
    return rows;
  };
  return (
    <Container fluid className="project-section">
      <Particle />
      <Container>
        <h1 className="project-heading">
          我们的 <strong className="purple">旅行 </strong>
        </h1>
        <p style={{ color: "white" }}>
          Here are a few places we've traveled.
        </p>
        {renderRows()}
      </Container>
    </Container>
  );
}

export default Travel;
